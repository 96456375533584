import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { SelectableCard } from '@vizeat/components/es6/components/SelectableCard'
import { Image } from '@vizeat/components/es6/components/Image'
import { Text } from '@vizeat/components/es6/components/Text'
import { SmallSpinner } from '@vizeat/components/es6/components/SmallSpinner'
import IconCross from '@vizeat/components/es6/assets/icons/IconCross'
import { useDeleteUserPaymentMethod } from 'hooks/queries/user/useUserPaymentMethodsQuery'

export function CreditCard({ paymentMethod, isSelected, onRemove, onSelect }) {
  const { t } = useTranslation()
  const { card = {}, id } = paymentMethod
  const { brand = '' } = card
  const formattedBrand = brand.toLowerCase() === 'american express' ? 'amex' : brand.toLowerCase()
  const matches = useMediaQuery('tablet')
  const { deletePaymentMethod, isLoading } = useDeleteUserPaymentMethod({
    onSuccess: onRemove,
  })

  function getCardBackground() {
    switch (brand.toLowerCase()) {
      case 'visa':
        return 'linear-gradient(135deg, #2048c4 0%, #2088c4 100%)'
      case 'amex':
        return 'linear-gradient(135deg, #77cdff 0%, #5791fa 100%)'
      case 'mastercard':
      default:
        return 'linear-gradient(135deg, #ff7877 0%, #fab957 100%)'
    }
  }

  function handleRemove(e) {
    e.stopPropagation()
    deletePaymentMethod(id)
  }

  return (
    <SelectableCard
      width={{ tablet: '100px', default: '200px' }}
      height={{ tablet: '60px', default: '120px' }}
      maxWidth='unset'
      background={getCardBackground()}
      borderRadius='md'
      color='black'
      p='8px'
      isSelected={isSelected}
      onClick={isLoading ? undefined : onSelect}
      onDelete={handleRemove}
      flexShrink={0}
    >
      {isLoading && (
        <Flex justifyContent='center' alignItems='center' height='100%'>
          <SmallSpinner color='white' />
        </Flex>
      )}

      {!isLoading && (
        <Flex flexDirection='column' justifyContent='space-between' height='100%' color='white'>
          <Box position='relative' mb='4px'>
            <Image height='20px' src={`/assets/images/icon-logo_${formattedBrand}.svg`} />
          </Box>
          <Flex flexDirection='column' mt='8px' mb='0' mx='0' lineHeight='8px'>
            {!matches && (
              <Text as='span' color='white' css={{ fontSize: '8px', opacity: '0.7' }}>
                {t('Booking::CreditCard::Card Number').toUpperCase()}
              </Text>
            )}
            <Text as='span' color='white' css={{ fontSize: matches ? '10px' : '12px' }}>
              {`XXXX XXXX XXXX ${matches ? '' : card.last4}`.toUpperCase()}
            </Text>
          </Flex>

          {!matches && (
            <Flex flexDirection='column' lineHeight='8px'>
              <Text as='span' color='white' css={{ fontSize: '8px', opacity: '0.7' }}>
                {t('Booking::CreditCard::Exp Date').toUpperCase()}
              </Text>
              <Text as='span' color='white' size='xs'>
                {card.exp_month}/{card.exp_year - 2000}
              </Text>
            </Flex>
          )}

          <Flex alignSelf='flex-end'>
            {matches && (
              <Text as='span' css={{ fontSize: '10px' }} color='white'>
                {card.last4}
              </Text>
            )}
            {!matches && (
              <Flex flexDirection='row' alignItems='center' color='white'>
                <IconCross mr='2px' fontSize='10px' />
                <Text as='span' onClick={handleRemove} css={{ fontSize: '8px' }}>
                  Delete card
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
    </SelectableCard>
  )
}

CreditCard.propTypes = {
  paymentMethod: PropTypes.shape({
    id: PropTypes.string,
    card: PropTypes.shape({
      last4: PropTypes.string,
      brand: PropTypes.string,
      exp_month: PropTypes.string,
      exp_year: PropTypes.string,
    }),
  }).isRequired,
  isSelected: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

CreditCard.defaultProps = {
  isSelected: false,
}
