import React, { useEffect, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { usePrevious } from 'hooks'
import { ButtonWithLoader } from '@vizeat/components/es6/components/ButtonWithLoader'
import { Box } from '@vizeat/components/es6/components/Box'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { InfiniteScroll } from '@vizeat/components/es6/components/InfiniteScroll'
import { gtmButtonClicked, gtmEventListDisplayed, gtmSearchResultsDisplayed, GTM_EVENT_TYPES } from 'gtm'

export function EventList({
  isLoading,
  templateColumns,
  gtmType,
  events,
  hasLoadMore,
  onLoadMore,
  loadingMore,
  render,
  renderSkeleton,
  totalCount,
  infiniteScroll,
}) {
  const prevEventsSize = usePrevious(events.size || 0)
  const skeletons = useMemo(() => Array.from({ length: prevEventsSize }, (_, index) => index), [prevEventsSize])

  const { t } = useTranslation()
  const { appCurrency: currency } = useCurrency()
  const canLoadMore = hasLoadMore && !!onLoadMore

  useEffect(() => {
    if (events.size) {
      gtmEventListDisplayed({ type: gtmType, events, currency })
      gtmSearchResultsDisplayed(events.size, totalCount)
    }
  }, [gtmType, events.size, events, currency, totalCount])

  return (
    <>
      <Grid
        maxWidth='1472px' // 5*272px (cards) + 4*16px (gaps) + 2*24px (padding)
        px={{ default: '0', tablet: '24px' }}
        mt={{ default: '0', tablet: '16px' }}
        gap='16px'
        mx='auto'
        templateColumns={templateColumns}
        justifyContent='center'
        justifyItems='center'
      >
        {isLoading && renderSkeleton ? skeletons.map(renderSkeleton) : events.map(render)}
      </Grid>

      {canLoadMore && !infiniteScroll && (
        <Box mt='16px' w='100%'>
          <ButtonWithLoader
            onClick={() => {
              onLoadMore()
              gtmButtonClicked({
                buttonId: 'showMoreEvents',
                buttonCta: t('EventList::Load More'),
                eventsSize: events.size,
              })
            }}
            loading={loadingMore}
          >
            {t('EventList::Load More')}
          </ButtonWithLoader>
        </Box>
      )}

      {canLoadMore && infiniteScroll && <InfiniteScroll canLoadMore onLoadMore={onLoadMore} />}
    </>
  )
}

EventList.propTypes = {
  templateColumns: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  events: PropTypes.immutable.list.isRequired,
  gtmType: PropTypes.string,
  hasLoadMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  renderSkeleton: PropTypes.func,
  infiniteScroll: PropTypes.bool,
  render: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
}

EventList.defaultProps = {
  templateColumns: 'repeat(auto-fill, 272px)',
  infiniteScroll: false,
  isLoading: false,
  gtmType: GTM_EVENT_TYPES.SEARCH,
  hasLoadMore: false,
  loadingMore: undefined,
  onLoadMore: undefined,
  renderSkeleton: undefined,
}
