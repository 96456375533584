import React from 'react'
import PropTypes from 'helpers/proptypes'
import { CreditCard } from './CreditCard'
import { ScrollableSlider } from 'components/shared/ScrollableSlider'

export function PaymentMethodList({ paymentMethods, currentPaymentMethod, onRemove, onSelect }) {
  if (paymentMethods.length === 0) return null

  return (
    <ScrollableSlider p='8px 4px' gap='4px'>
      {paymentMethods.map((paymentMethod, index) => (
        <CreditCard
          key={paymentMethod.id}
          paymentMethod={paymentMethod}
          isSelected={currentPaymentMethod && paymentMethod.id === currentPaymentMethod.id}
          onRemove={onRemove}
          onSelect={() => onSelect(paymentMethod, index)}
        />
      ))}
    </ScrollableSlider>
  )
}

PaymentMethodList.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({})),
  currentPaymentMethod: PropTypes.immutable.record,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

PaymentMethodList.defaultProps = {
  paymentMethods: [],
  currentPaymentMethod: undefined,
}
