import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { deleteUserPaymentMethod, getUserPaymentMethods } from 'api/user'
import { STALE_TIME } from 'helpers/query'

const userPaymentQueryKey = ['user', 'paymentMethods']

export function useUserPaymentMethodsQuery(reactQueryOptions) {
  return useQuery({
    staleTime: STALE_TIME,
    queryKey: userPaymentQueryKey,
    queryFn: getUserPaymentMethods,
    ...reactQueryOptions,
  })
}

export function useDeleteUserPaymentMethod({ onSuccess }) {
  const queryClient = useQueryClient()
  const { mutate, isError, isLoading } = useMutation({
    mutationFn: (id) => deleteUserPaymentMethod(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userPaymentQueryKey })
      onSuccess?.()
    },
  })

  return { deletePaymentMethod: mutate, isError, isLoading }
}
