import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { Text } from '@vizeat/components/es6/components/Text'
import { Avatar } from 'components/shared/images'
import { Tooltip } from '@vizeat/components/es6/components/Tooltip'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { useGTMButtonClickOnce } from 'hooks/tracking'

const TruncatedText = styled((props) => <Text as='span' size='s' {...props} />)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 8px;
`

const StyledMoreAvatarsButton = styled((props) => <Flex as='button' type='button' {...props} />)`
  background: transparent;
  border: none;
  gap: 8px;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.eatwithOrange};
  }
`

function MoreAvatarsButton({ onClick, size }) {
  const { t } = useTranslation()
  const handleGtmButtonClickOnce = useGTMButtonClickOnce({ buttonId: 'more', buttonCta: t('AvatarList::More') })

  const handleClick = () => {
    handleGtmButtonClickOnce()
    onClick()
  }

  return (
    <StyledMoreAvatarsButton flexDirection='column' alignItems='center' p='0' fontSize='14px' onClick={handleClick}>
      <Flex
        justifyContent='center'
        alignItems='center'
        width={`${size}px`}
        height={`${size}px`}
        border='sm'
        borderRadius='full'
      >
        •••
      </Flex>
      {t('AvatarList::More')}
    </StyledMoreAvatarsButton>
  )
}

MoreAvatarsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
}

export function AvatarList({ bookings, maxAvatars, onMoreAvatarsButtonClick, avatarSize }) {
  const hasMoreAvatars = maxAvatars && bookings.length > maxAvatars
  const isTabletOrLarger = useMediaQuery('tablet')

  const bookedSeats = useMemo(() => {
    const bookingsList = hasMoreAvatars ? bookings.slice(0, maxAvatars - 1) : bookings

    const bookedSeatsByUserId = bookingsList.reduce((acc, { user, groupStatus, seats }) => {
      if (
        !user || // prevent crash in preprod if the user doesn't exist (dump issue)
        groupStatus !== 'successful'
      ) {
        return acc
      }

      return {
        ...acc,
        [user.id]: {
          guest: user,
          seats: (acc[user.id]?.seats || 0) + seats,
        },
      }
    }, {})

    return Object.values(bookedSeatsByUserId)
  }, [bookings, hasMoreAvatars, maxAvatars])

  return (
    <Flex flexDirection='row' textAlign='center' wrap='wrap' gap={isTabletOrLarger ? '16px' : '8px'}>
      {bookedSeats.map(({ guest, seats }) => {
        return (
          <Flex key={guest.id} flexDirection='column' justifyItems='center' alignItems='top' width={avatarSize}>
            <Box position='relative'>
              <Flex justifyItems='flex-start'>
                <Tooltip
                  position='top'
                  alignLeft
                  renderContent={() => (
                    <Text as='span' size='s' color='white'>
                      {guest.firstname}
                    </Text>
                  )}
                >
                  <Avatar user={guest} size={avatarSize} />
                </Tooltip>
              </Flex>

              {seats > 1 && (
                <Flex
                  alignItems='center'
                  justifyContent='center'
                  position='absolute'
                  bottom='-2px'
                  right='-5px'
                  width='37%'
                  height='37%'
                  borderRadius='full'
                  bg='eatwithOrange'
                >
                  <Text as='span' size='xs' color='white'>
                    {seats}
                  </Text>
                </Flex>
              )}
            </Box>
            <TruncatedText>{guest.firstname}</TruncatedText>
          </Flex>
        )
      })}

      {hasMoreAvatars && <MoreAvatarsButton size={avatarSize} onClick={onMoreAvatarsButtonClick} />}
    </Flex>
  )
}

AvatarList.propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  maxAvatars: PropTypes.number,
  onMoreAvatarsButtonClick: PropTypes.func,
  avatarSize: PropTypes.number,
}

AvatarList.defaultProps = {
  maxAvatars: null,
  onMoreAvatarsButtonClick: undefined,
  avatarSize: 70,
}
